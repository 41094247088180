// Main.js
import React from "react";
import "./main.css";

function Main({heroDescription, projects, workExp}) {

    // generate projects
    let renderedProjects = [];
    for(var i = 0; i < projects.list.length; i++) {
        renderedProjects.push(
            <section key={i} className="projects">
                <h3 className="projectHeader">Projects</h3>
                <article className="project">
                    {projects.list[i].img_name != undefined ? <img src={'/images/projectIcons/' + projects.list[i].img_name} alt="projectImage"/> : undefined}
                    <h4>{projects.list[i].title}</h4>
                    <p>{projects.list[i].description}</p>
                    <a href={projects.list[i].link} target="_blank">{projects.list[i].link_description}</a>
                </article>
            </section>
        );
    }

    // generate work experience
    let renderedWorkExp = [];
    const month = ["Jan.","Feb.","Mar.","Apr.","May","June","July","Aug.","Sep.","Oct.","Nov.","Dec."];
    for(var i = 0; i < workExp.list.length; i++) {
        let dateCutoff = new Date("2000-01-01 00:00:00");
        let startDate = new Date(workExp.list[i].startDate);
        let endDate = new Date(workExp.list[i].endDate);
        var tmpEndDate = "";
        if(workExp.list[i].endDate == "" || dateCutoff.getTime() > endDate.getTime()) {
            tmpEndDate = "Present";
        } else {
            tmpEndDate = month[endDate.getMonth()] + " " + endDate.getFullYear();
        }
        renderedWorkExp.push(
            <article key={i} className="workExp">
                <h3 className="jobTitle">{workExp.list[i].jobTitle}</h3>
                <small className="jobDuration">{month[startDate.getMonth()] + " " + startDate.getFullYear()} - {tmpEndDate}</small>
                <p className="jobDesc">{workExp.list[i].jobDescription.replace("\n", "\n")}</p>
            </article>
        );
    }

    return (
        <main>
            <section className="hero">
                <article>
                    <p className="heroBio">{heroDescription}</p>
                </article>
            </section>
            {renderedProjects}
            <section className="workExpSec">
                <h3 className="workExpHeader">Work Experience</h3>
                {renderedWorkExp}
            </section>
        </main>
    )
}

export default Main;